:root { 
--gray_400:#c3bbbb; 
--blue_900:#0541a8; 
--gray_401:#c6c5c5; 
--deep_purple_900:#0f059e; 
--blue_800:#165eaa; 
--gray_200:#eaeaec; 
--light_blue_800:#0071ce; 
--black_900_1e:#0000001e; 
--bluegray_900:#2b2b2b; 
--bluegray_800:#333c4e; 
--teal_300:#30bcb0; 
--black_900:#000000; 
--yellow_800:#f5ac23; 
--yellow_802:#f5ac30; 
--yellow_801:#fcb61a; 
--blue_400:#4a90e2; 
--indigo_900:#000b6c; 
--white_A700:#ffffff; 
}